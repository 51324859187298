<template>
  <div class="login">
    <Header />

    <div class="landing">
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div
              v-if="is_loaded"
              class="auth-card"
            />
            <loading v-else />
          </div>
        </div>
      </div>
    </div>

    <guest-footer />
  </div>
</template>

<script>
    import Header from "@/components/header/Header.vue";
    import GuestFooter from "@/components/guest/Footer.vue";

    export default {
        components: {
          Header,
          GuestFooter
        },
        data() {
            return {
                code: {
                    code: this.$route.params.code
                }
            }
        },

        mounted() {
            this.fetch()
        },
        methods: {
            fetch() {
                this.is_valid = true;
                this.is_loaded = true;
                this.verifyCompany();
            },
            verifyCompany() {
                this.$axios.post('/api/verify-company', this.code).then(() => {
                    this.$toastr.s('Company approved', 'Success');
                    if (this.$auth.isAuthenticated()) {
                        window.location.href = '/dashboard/companies';
                    }
                }).catch(() => {
                    this.$toastr.e('Code not valid!', 'Error');
                    if (this.$auth.isAuthenticated()) {
                        window.location.href = '/dashboard';
                    }
                })
            }
        }
    }
</script>

